import PropTypes from "prop-types";
import { mySrcmConfig, l } from "./common-utils";

const profileApiUrl = (api, param1 = "", param2 = "") => {
  let path = "";
  switch (api) {
    case "cities-id":
      path = `/api/v2/cities/${param1}/`;
      break;
    case "abhyasis-search":
      path = `/api/v2/abhyasis/search/?${l(param1)}=${param2}`;
      break;
    case "abhyasis-search-id":
      path = `/api/v2/abhyasis/search/${param1}/`;
      break;
    case "myprofile-preceptors":
      path = `/api/v2/myprofile/preceptors/?page_size=200&${l(
        param1
      )}=${param2}`;
      break;
    case "myprofile-preceptors-id":
      path = `/api/v2/myprofile/preceptors/${param1}/`;
      break;
    default:
      if (api.startsWith("/api/v2/") || api.startsWith("/api/v3/")) {
        path = api;
      } else {
        path = `/api/v2/${l(api)}/`;
      }
      break;
  }
  return mySrcmConfig.profileServer + path;
};

profileApiUrl.propTypes = {
  api: PropTypes.string.isRequired,
  param1: PropTypes.string,
  param2: PropTypes.string,
};

export default profileApiUrl;
