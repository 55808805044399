const publicConfig =
  require("./src/components/PublicComponents/AppConfig").default;
const publicBlankConfig =
  require("./src/components/PublicComponentsBlank/AppConfig").default;
const homeConfig = require("./src/components/HomeComponents/AppConfig").default;
// const homeConfigPublicHdr =
//   require("./src/components/HomeComponents/AppConfigPublicHdr").default;
const trainerConfig = require("./src/components/Trainer/AppConfig").default;
const daajiConfig = require("./src/components/Daaji/AppConfig").default;
const fhubConfig = require("./src/components/FunctionaryHub/AppConfig").default;
const ashramConfig = require("./src/components/AshramOffice/AppConfig").default;
const preceptorAppConfig =
  require("./src/components/PreceptorSearch/AppConfig").default;
const approvalsConfig = require("./src/components/Approvals/AppConfig").default;
const trainerMenuItems =
  require("./src/components/MenuItems/trainerItems").default;

// publicConfig is (no login but has header)
// homeConfig (user should be logged in) is default if not specified in the map below
const appConfigRoutes = {
  "/trainer/": {
    config: trainerConfig,
    onHome: "items",
    menuItems: trainerMenuItems,
    order: 2,
    linkText: "Trainer's Section",
  },
  "/daaji/": {
    config: daajiConfig,
    onHome: "link",
    linkText: "Daaji's Section",
    order: 41,
  },
  "/functionary-hub/": {
    config: fhubConfig,
    onHome: "link",
    linkText: "Functionary Hub",
    order: 43,
  },
  "/fpms/": {
    config: daajiConfig,
    onHome: "link",
    linkText: "FPMS",
    order: 42,
  },
  "/ashram-office/": {
    config: ashramConfig,
    onHome: "link",
    linkText: "Ashram Office",
    order: 44,
  },
  "/account/": {
    config: homeConfig,
  },
  "/central-team-approvals/": {
    config: approvalsConfig,
    onHome: "link",
    linkText: "Central Team Approvals",
    order: 66,
  },
  // "/profile/": {
  //   config: homeConfigPublicHdr,
  // },
  "/qr/": {
    config: publicConfig,
  },
  "/otp/": {
    config: publicConfig,
  },
  "/h/": {
    config: publicBlankConfig,
  },
  "/embedded/": {
    config: publicConfig,
  },
  "/preceptor-mgmt/": {
    config: preceptorAppConfig,
    onHome: "link",
    linkText: "Preceptor Search App",
    order: 88,
  },
  "/unsubscription/": {
    config: publicConfig,
  },
  "/unsubscribe/": {
    config: publicConfig,
  },
};

module.exports = appConfigRoutes;
