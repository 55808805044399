/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import Avatar from "gatsby-plugin-hfn-profile/auth/Avatar";
import SignInButton from "gatsby-plugin-hfn-profile/auth/SignInButton";
import applyProdQaConfig from "sites-common/utils/applyProdQaConfig";
import PropTypes from "prop-types";

const Header = ({ goHome, isHome }) => {
  return (
    <Box>
      <Flex sx={{ bg: "muted" }}>
        <Box px={2} sx={{ py: 2, flex: "1 1 auto" }}>
          <div
            role="button"
            tabIndex="0"
            sx={{ cursor: "pointer", fontSize: "2em" }}
            onClick={!isHome ? goHome : null}
            onKeyDown={!isHome ? goHome : null}
          >
            Heartfulness Preceptors Search
          </div>
        </Box>
        <Box p={2}>
          <Avatar />
          <SignInButton btnText="Sign-In" />
        </Box>
      </Flex>
    </Box>
  );
};

Header.propTypes = {
  goHome: PropTypes.func.isRequired,
  isHome: PropTypes.bool.isRequired,
};

const allowedUsers = require("./allowed-users.json");

const Config = {
  Header,
  accessControl: {
    msgUnAuthorized: (r) =>
      `Hello ${r.email}, You are not authorized to access this page!`,
    showSignInButton: "Sign In to access this page",
    conditions: (r) =>
      applyProdQaConfig({
        prod: allowedUsers.includes(r?.abhyasiId),
        qa: true,
      }),
  },
};
export default Config;
