/** @jsx jsx */
import { jsx, Text, Image, Card } from "theme-ui";
import { Link } from "gatsby";
import { DefaultButton, Stack, StackItem } from "office-ui-fabric-react";
import HFNMeditate from "../../assets/hfn-meditate.png";
import HFNLights from "../../assets/unity.jpg";
import HFNSearch from "../../assets/search.jpg";

// import AnnouncementsBoard from "../Trainer/AnnouncementsBoard";

const C = (props) => (
  <Card
    sx={{
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "2px",
      border: "2px solid ",
      borderColor: "background",
      backgroundColor: "background",
      p: 2,
      maxWidth: "600px",
    }}
    {...props}
  />
);

const CImage = (props) => (
  <Image alt="-" sx={{ height: "100px", width: "100px" }} {...props} />
);

//
const trainerMenuItems = [
  // {
  //   FreeFlowContent: () => (
  //     <C>
  //       <AnnouncementsBoard />
  //     </C>
  //   ),
  // },
  {
    FreeFlowContent: () => (
      <C>
        <Text variant="title">Registering New Introductions</Text>
        <Stack horizontal tokens={{ padding: 10, childrenGap: 10 }}>
          <StackItem sx={{ width: "100px" }}>
            <CImage src={HFNMeditate} />
          </StackItem>
          <StackItem>
            <div sx={{ textAlign: "justify", textJustify: "inter-word" }}>
              Trainers can register in multiple ways after initiating new
              seekers (3 sittings). You can directly register the seeker. Or
              alternatively, you can provide a special code or link to seekers
              for self-registration. With this referral code, multiple seekers
              (group introductions) can register; their requests would get
              auto-approved and e-welcome cards are sent to them over email.
            </div>
          </StackItem>
        </Stack>

        <Stack sx={{ my: 2 }} horizontal tokens={{ childrenGap: 10 }}>
          <Link to="/trainer/register-practitioner">
            <DefaultButton text="Register Seeker" />
          </Link>

          <Link to="/trainer/qrcode">
            <DefaultButton text="Share Code / Link" />
          </Link>
        </Stack>
      </C>
    ),
  },

  {
    FreeFlowContent: () => (
      <C>
        <Text variant="title">Spiritual Work</Text>
        <Stack
          horizontal
          horizontalAlign="space-evenly"
          tokens={{ padding: 10, childrenGap: 10 }}
        >
          <StackItem>
            <Stack tokens={{ childrenGap: 15 }}>
              <StackItem>
                <div>Abhyasis being mentored by me.</div>

                <Link to="/trainer/abhyasi-mentoring">
                  <DefaultButton text="My Mentee Abhyasis" />
                </Link>
              </StackItem>
              <StackItem>
                <div>Special Sittings Journal 2022</div>
                <Link to="/trainer/special-journal">
                  <DefaultButton text="View My Journal" />
                </Link>
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem sx={{ width: "100px" }}>
            <CImage src={HFNSearch} />
          </StackItem>
        </Stack>
      </C>
    ),
  },

  {
    FreeFlowContent: () => (
      <C>
        <Text variant="title">My Pending Approvals</Text>
        <Stack horizontal tokens={{ padding: 10, childrenGap: 10 }}>
          <StackItem sx={{ width: "100px" }}>
            <CImage src={HFNLights} />
          </StackItem>
          <StackItem>
            <div sx={{ mb: 2 }}>
              New Practitioners after practising regularly for three to six
              months can apply for{" "}
              <span sx={{ whiteSpace: "nowrap" }}>
                &quot;Regular Heartfulness ID&quot;
              </span>
              .
              <br />
              Trainers need to approve their requests.
            </div>

            <Link to="/trainer/pending-approvals">
              <DefaultButton text="View Pending Requests" />
            </Link>
          </StackItem>
        </Stack>
      </C>
    ),
  },

  // {
  //   label: "View",
  //   subLabel: "Practitioners assigned to me >>",
  //   to: "/trainer/my-practitioners",
  //   icon: HFNLights,
  //   key: "3",
  //   isTrainer: true,
  // },
  // {
  //   label: "Search",
  //   subLabel: "Search Practitioners >>",
  //   to: "/trainer/search-practitioners",
  //   icon: HFNSearch,
  //   key: "4",
  //   isTrainer: true,
  // },
];

export default trainerMenuItems;
