import React from "react";
import SiteHeader from "../SiteHeader";

function msgUnAuthorized(r) {
  return (
    <div>
      This page is only for functionaries. <b>{r.email}</b> does not belong to a
      functionary. Please sign out and sign in with a valid functionary account.{" "}
      <br />
      <br />
      <a href="/">Go back to Home Page</a>
    </div>
  );
}

const accessControl = {
  msgUnAuthorized,
  showSignInButton: "Sign In to access this page",
  conditions: (r) => !!r,
};

const AppConfig = {
  Header: SiteHeader,
  accessControl,
};
export default AppConfig;
