/** @jsx jsx */

import PublicHeaderBlank from "../SiteHeader/blank";

const Header = PublicHeaderBlank;

const accessControl = null;

const AppConfig = {
  Header,
  accessControl,
};
export default AppConfig;
