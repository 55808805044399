import React from "react";
import applyProdQaConfig from "sites-common/utils/applyProdQaConfig";
import SiteHeader from "../SiteHeader";

function msgUnAuthorized(r) {
  return (
    <div>
      <b>{r.email}</b> does not have access to this page. Please sign out and
      sign in with a different account. <br />
      <br />
      <a href="/">Go back to Home Page</a>
    </div>
  );
}

const accessControl = {
  msgUnAuthorized,
  showSignInButton: "Sign In to access this page",
  conditions: (r) =>
    applyProdQaConfig({
      prod:
        !!r &&
        [
          "7bEqZpoxHBhp1KFqRV8nJSfWFG73", // hari qa
          "mNO5bE1W1oaKfLbZh7YA5bF7mfp1", // hari prod
          "4RfM07XY77ft6TNucqis67U5fgH2", // daaji
          "WAnvcNysjXWCEOpTWjqbeiOvgpU2", // sanjay
          "lq0BspCShtf50CqFvDv59oeMoH32", // vamsi
          "w1djbLDgZeOAHWlXnsasHKeP6rw1", // kishore
          "RF6AUmoFpIVPMLytrcJpPYG9US72", // lalitha
        ].includes(r.userFirebaseUid),
      qa: true,
    }),
};

const AppConfig = {
  Header: SiteHeader,
  accessControl,
};
export default AppConfig;
