import React from "react";
import applyProdQaConfig from "sites-common/utils/applyProdQaConfig";
import SiteHeader from "../SiteHeader";

function msgUnAuthorized(r) {
  return (
    <div>
      <b>{r.email}</b> does not have access to this page. Please sign out and
      sign in with a different account. <br />
      <br />
      <a href="/">Go back to Home Page</a>
    </div>
  );
}

const accessControl = {
  msgUnAuthorized,
  showSignInButton: "Sign In to access this page",
  conditions: (r) =>
    applyProdQaConfig({
      prod:
        !!r &&
        [
          "7bEqZpoxHBhp1KFqRV8nJSfWFG73", // hari qa
          "mNO5bE1W1oaKfLbZh7YA5bF7mfp1", // hari prod
          "Hpn3d6Ba0bVMoAKYTMtu0Vu3EiF2", // vineet ranawat
          "2Zhv73vmjVdjrAYRCQKSeNhddI12", // hari polisetty
          "mysrcm-03cdc6b841ba0131764711e5f1f4e47d", // suman rani
          "Qgg4NEbYTwSbcrTVXtQfpHqyavs2", // ravi shankar
        ].includes(r.userFirebaseUid),
      qa: true,
    }),
};

const AppConfig = {
  Header: SiteHeader,
  accessControl,
};
export default AppConfig;
