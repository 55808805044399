/** @jsx jsx */
import SiteHeader from "../SiteHeader";

const Header = SiteHeader;
const accessControl = {
  msgUnAuthorized: (r) =>
    `Hello, ${r.name}, You do not have access to this page!`,
  showSignInButton: "Sign In to access this page",
  conditions: (r) => !!r && (r?.roles || []).includes("seeker_admin"),
};

const AppConfig = {
  Header,
  accessControl,
};
export default AppConfig;
