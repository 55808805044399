/** @jsx jsx */

import PublicHeader from "../SiteHeader/public";

const Header = PublicHeader;

const accessControl = null;

const AppConfig = {
  Header,
  accessControl,
};
export default AppConfig;
