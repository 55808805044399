/** @jsx jsx */
import { navigate } from "gatsby";
import { jsx } from "theme-ui";
import SiteHeader from "../SiteHeader";

const goHome = () => navigate("/");
const Header = (props) => <SiteHeader {...props} homeUrl="/" goHome={goHome} />;
const accessControl = {
  msgUnAuthorized: (r) => `Hello, ${r.name}`,
  showSignInButton: "Sign In to access this page",
  conditions: (r) => !!r,
};

const AppConfig = {
  Header,
  accessControl,
};
export default AppConfig;
